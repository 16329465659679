import * as Sentry from "@sentry/react";
import random_generator from "./random_generator"; 

//General purpose fetch  
const general_fetch = async ({ url, full_url, method, headers, body, is_form_data, credentials, data_field, req_resp }) => {
	try {
		if (!data_field) {
			data_field = 'data';
		}
		if (full_url) {
			url = full_url;
		} else {
			url = global.config.server_path + url;
		}
		if (!url) {
			throw 'invalid url provided';
		}
		if(!method) {
			method = 'POST';
		}
		if (!is_form_data) {
			if (!headers) {
				headers = {
					'Content-Type': 'application/json'
				};
			}
			if (body) {
				body = JSON.stringify(body);
			}
		}
		if (!credentials) {
			credentials = 'include';
		}
		
        var options = { method, headers, credentials, body };
        //console.log("options======>" , options)
        try{
			var resp = await fetch(url, options);
		}catch(err){
			console.log("Failed to fetch ", url)
			throw new Error("Network Error, failed to fetch")
		}
        
		try{
			resp = await resp.json();
		}catch(err){
			console.log("Network Error, non json response ", url)
			throw new Error("Network Error, non json response ")
		}
		
		if(req_resp){
			return resp;
		}
        if (resp.response_code == 1 || resp.response_code == 0) {
        	return resp[data_field];
        }else {
        	throw new Error('Server call failed with response ---> ' + JSON.stringify(resp)); 
        }
	} catch(err) {
		if((resp && resp.status===401) || resp && resp.response_code == 401 || (global.config.server_path+'user/get_details' == url && resp && resp.is_authenticated == false)){
			// global.config.check_401(true)
			// alert.info('You are not logged in !')
			console.log('Unauthorized')
			// alert.error("You are not authorized to access this !")
		}else if(resp && resp.action == 'TOAST_ERROR'){
			console.log('Toast error')
			global.set_toast_error(resp.message ? resp.message : '')
		}else if(err.message.includes("Network")){
			err.name = "Network Error : " + err.name 
			Sentry.configureScope(scope => {
				if (!is_form_data)
				scope.setTag("request_id", headers['x-request-id']);
			});
			Sentry.captureException(err, {fingerprint: ["message"]})
		}else{
			if(err.constructor.name.includes("Error") != true) err=new Error(err)
			 err.name = "Captured error in the API call " + url + (body ? ", body: "+ JSON.stringify(body) : '') + (resp ? ", response: "+ JSON.stringify(resp) : '') + " : " + err.name
			console.error(err);
			Sentry.configureScope(scope => {
				if (!is_form_data)
				scope.setTag("request_id", headers['x-request-id']);
			});
			Sentry.captureException(err);
		}
		return Promise.reject({ err, resp,info: 'Error in general_fetch' })
	}
} 

window.general_fetch = general_fetch;
export default general_fetch ;
