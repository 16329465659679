import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { date_string } from "../../utils/date_string";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { colourStyles, colourStyles1, colourStyles2 } from "../../utils/react_select_styles.js";
import mixpanel_event_types from "../../utils/mixpanel_event_types";
import { Switch, DatePicker } from 'antd';

const animatedComponents = makeAnimated();

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Usage Graph',
    },
  },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

function get_between_dates(start, end, aggregate) {
	const dates = [];
	for (let currentDate = end; currentDate > start; (aggregate == 'weeks' ? currentDate.setDate(currentDate.getDate() - 7) : currentDate.setDate(currentDate.getDate() - 1))) {
		let date = new Date(currentDate);
		let formattedDate;
        if(aggregate == 'days') {
            formattedDate = date.toISOString().split('T')[0];
        } else {
            formattedDate = `${date.toISOString().split('T')[0]}`;
            date.setDate(date.getDate() - 7);
            formattedDate = `${date.toISOString().split('T')[0]} - ${formattedDate}`;
        }
		dates.push(formattedDate);
	}
    dates.reverse();
	return dates;
}

function get_last_n_dates(n, aggregate) {
    let endDate = new Date();
    let startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - n);
    return get_between_dates(startDate, endDate, aggregate);
}

const UsageGraph = ({store_info, set_page_loader, cs_org_users}) => {
	const [data, set_data] = useState({  labels:['January'],
		datasets: [
		  {
			label: 'Dataset 1',
			data: ['January'].map(() => faker.datatype.number({ min: 0, max: 0 })),
			backgroundColor: 'rgba(255, 99, 132, 0.5)',
		  },
		  {
			label: 'Dataset 2',
			data: ['January'].map(() => faker.datatype.number({ min: 0, max: 0 })),
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
		  },
		],});
	const [api_data_org_level, set_api_data_org_level] = useState();	
	const [api_data, set_api_data] = useState();
	const [api_data_user_level, set_api_data_user_level] = useState();
	const [selected_usage_events, set_selected_usage_events] = useState([{ value: 'version_save', label: 'Version Save' }]);
	const [timeline, set_timeline] = useState('last_30_days');
	const [selected_graph, set_selected_graph] = useState({value:'bar', label: 'Bar'});
	const [user_level, set_user_level] = useState(false);
	const [selected_user_id, set_selected_user_id] = useState();
    const [custom_time_range, set_custom_time_range] = useState(null);
    const [aggregation, set_aggregation] = useState({value: 'days', label: 'Days'});

	const fetch_daywise_events = async(org_id, {start,end}={start:null,end:null}) => {
		try {
			const full_url = global.config.analytics_path + 'api/daywise_events'; 
			let end_time = end ? end : new Date()
			let start_time = new Date(new Date().toDateString())
			start_time.setMonth(start_time.getMonth()-3)
            if(start) { start_time = start }
			let body = {start_time: start_time.toISOString(), end_time: end_time.toISOString(), timezone_offset: new Date().getTimezoneOffset(), org_id}
			// let resp = await general_fetch({url:'cs_backend/activity/update', body,});
			set_page_loader(true, 'Please wait...')
			let resp = await fetch(full_url, {
									method: 'POST', 
									headers: {
										'Content-Type': 'application/json',
									},
									body: JSON.stringify(body),
								})
			resp = await resp.json()
			set_page_loader(false)
			console.log('graph22', resp)
			set_api_data_org_level(resp)
		}catch (err) {
			set_page_loader(false)
			console.error(err);
		}
	}

	const fetch_user_daywise_events = async(org_id, {start,end}={start:null,end:null}) => {
		try {
			const full_url = global.config.analytics_path + 'api/user_daywise_events'; 
			let end_time = end ? end : new Date()
			let start_time = new Date(new Date().toDateString())
			start_time.setMonth(start_time.getMonth()-3)
            if(start) { start_time = start }
			let body = {start_time: start_time.toISOString(), end_time: end_time.toISOString(), timezone_offset: new Date().getTimezoneOffset(), org_id}
			// let resp = await general_fetch({url:'cs_backend/activity/update', body,});
			let resp = await fetch(full_url, {
									method: 'POST', 
									headers: {
										'Content-Type': 'application/json',
									},
									body: JSON.stringify(body),
								})
			resp = await resp.json()
			set_api_data_user_level(resp)
		}catch (err) {
			console.error(err);
		}
	}

	useEffect(() => {
		if(api_data){
			let labels
			if(timeline === 'last_7_days'){
				labels = get_last_n_dates(7, aggregation.value)
			}else if(timeline === 'last_30_days'){
				labels = get_last_n_dates(30, aggregation.value)
			}else if(timeline === 'last_90_days'){
				labels = get_last_n_dates(90, aggregation.value)
			}else{
                labels = get_between_dates(new Date(custom_time_range[0]), new Date(custom_time_range[1]), aggregation.value)
            }
			let shortened_labels, week_aggregated_data = {};
            if(aggregation.value == 'days') {
                shortened_labels = labels.map(x => date_string(x).substring(0, 7));
            } else if(aggregation.value == 'weeks') {
                shortened_labels = labels.map(x => `${date_string(x.split(' - ')[0]).substring(0, 7)} - ${date_string(x.split(' - ')[1]).substring(0, 7)}`)
                for(let selected of selected_usage_events) {
                    let v = selected.value;
                    for(let label of labels) {
                        let s = new Date(label.split(' - ')[0]), e = new Date(label.split(' - ')[1]);
                        for(let x in api_data) {
                            if(new Date(x) < e && new Date(x) >= s) {
                                if(week_aggregated_data[label]) {
                                    if(week_aggregated_data[label][v]) {
                                        week_aggregated_data[label][v] += api_data[x] && api_data[x][v] ? api_data[x][v] : 0;
                                    } else {
                                        week_aggregated_data[label][v] = api_data[x] && api_data[x][v] ? api_data[x][v] : 0;
                                    }
                                } else {
                                    week_aggregated_data[label] = {[v]: api_data[x] && api_data[x][v] ? api_data[x][v] : 0 }
                                }
                            }
                        }
                    }
                }
            }
			set_data({labels: shortened_labels, datasets:[{label:'Number of Events', 
                data: labels.map((x) => selected_usage_events && selected_usage_events.length ? selected_usage_events.reduce((final, event) => {
                    let val;
                    if(aggregation.value == 'days') {
                        val = (api_data[x] && api_data[x][event.value]) ? final+api_data[x][event.value] : final
                    } else if(aggregation.value == 'weeks') {
                        val = (week_aggregated_data[x] && week_aggregated_data[x][event.value]) ? final+week_aggregated_data[x][event.value] : final
                    }
                    return val
                }, 0) : 0), 
                backgroundColor:!user_level ? '#ADD8E6' : '#D8BFD8', borderColor:'#5e72e4'}]})
		}
	}, [api_data, timeline, selected_usage_events, aggregation]);

	useEffect(() => {
		if(store_info && store_info.id) {
			fetch_daywise_events(store_info.id)
			fetch_user_daywise_events(store_info.id)
			if(store_info.all_users && store_info.all_users.length){
				set_selected_user_id(store_info.all_users.filter(o => !o.email.includes('@infurnia.com'))[0].id)
			}
		}
	}, [store_info]);

	// useEffect(() => {
	// 	console.log('graphh', api_data&&api_data.body)
	// }, [data, api_data]);

	useEffect(() => {
		if(user_level){
			if(selected_user_id){
				set_api_data(api_data_user_level[selected_user_id] ? api_data_user_level[selected_user_id] : {})
			}else{
				set_api_data({})
			}
		}else{
			set_api_data(api_data_org_level)
		}
	}, [user_level, selected_user_id, api_data_org_level, api_data_user_level]);

    useEffect(() => {
        if(store_info && store_info.id) {
            if(timeline == "custom") {
                fetch_daywise_events(store_info.id, {start: new Date(custom_time_range[0]), end: new Date(custom_time_range[1])})
                fetch_user_daywise_events(store_info.id, {start: new Date(custom_time_range[0]), end: new Date(custom_time_range[1])})
            } else {
                fetch_daywise_events(store_info.id);
                fetch_user_daywise_events(store_info.id);
            }
        }
    }, [custom_time_range])

	const onChangeDate = (date, dateString) => {
        if(date && date.length) {
            date[0] = date[0].add(5, 'hour'); date[0] = date[0].add(30, 'minute')
            date[1] = date[1].add(5, 'hour'); date[1] = date[1].add(30, 'minute')
            set_custom_time_range(date);
            set_timeline("custom")
        } else {
            set_custom_time_range(date);
        }
	}

    return(
		<div className="p-2">
			<div className="flex_property mb-4" style={{justifyContent:'flex-end', fontSize:'14px'}}>
				<div className="flex_center mr-4" style={{zIndex: 100}}>
					<Select
						// closeMenuOnSelect={false}
						// components={animatedComponents}
						value={aggregation}
						// isMulti
						options={[{value: 'days', label: 'Days'}, {value: 'weeks', label: 'Weeks'}]}
						styles={colourStyles}
						onChange={(selectedOptions) => set_aggregation(selectedOptions)}
					/>
				</div>
				<div className="flex_center" style={{boxShadow:'0 0 1px 2px rgb(0 0 0/5%),0 1px 2px -2px rgb(0 0 0/6%),0 1px 3px 0 rgb(0 0 0/7%)', padding:'12px 16px', borderRadius:'6px'}}>
					<div>Org Usage</div>
					<Switch className="mx-2" value={user_level} onChange={e => set_user_level(!user_level)} size="small" />
					<div>User Usage</div>
				</div>
			</div>
			<div className="flex_property" style={{justifyContent:'space-between'}}>
				<div className="custom_time_filter">
					<div
						className={`nav-item ${timeline === 'last_7_days' && 'selected'}`}
						onClick={() => { set_timeline('last_7_days'); set_custom_time_range(null)}}
					>
						Last 7 days
					</div>
					<div
						className={`nav-item ${timeline === 'last_30_days' && 'selected'}`}
						onClick={() => { set_timeline('last_30_days'); set_custom_time_range(null)}}
					>
						Last 30 days
					</div>
					<div
						className={`nav-item ${timeline === 'last_90_days' && 'selected'}`}
						onClick={() => { set_timeline('last_90_days'); set_custom_time_range(null)}}
					>
						Last 90 days
					</div>
                    <div className="pl-2"> 
                        <DatePicker.RangePicker
                            id = 'filter_date'
                            format="YYYY-MM-DD"
                            onChange = {onChangeDate}
                            value = {custom_time_range ? custom_time_range : []}
                            disabledDate={(d) => new Date(d) > new Date()}
                            // defaultValue = {from_date}
                        />
                    </div>
				</div>
				<div className="flex_center" style={{ fontSize: '12px' }}>
					{
						user_level ?
						<>
							<div className="mr-2">User:</div>
							<Select
								// closeMenuOnSelect={false}
								components={animatedComponents}
								value={{value:selected_user_id, label:selected_user_id && store_info && store_info.all_users && store_info.all_users.length ? store_info.all_users.find(o => o.id === selected_user_id).first_name : ''}}
								// isMulti
								options={store_info && store_info.all_users && store_info.all_users.length ? store_info.all_users.filter(o => !o.email.includes('@infurnia.com')).map(x => ({value:x.id, label:x.first_name})) : []}
								styles={colourStyles}
								onChange={(selectedOptions) => set_selected_user_id(selectedOptions.value)}
							/>
							<div className="mr-2"></div>
						</>:''
					}
					<div className="mr-2">Usage Events:</div>
					<Select
						// closeMenuOnSelect={false}
						components={animatedComponents}
						value={selected_usage_events}
						isMulti
						options={mixpanel_event_types}
						styles={colourStyles}
						onChange={(selectedOptions) => set_selected_usage_events(selectedOptions)}
					/>
					<div className="mr-2"></div>
					<Select
						// closeMenuOnSelect={false}
						components={animatedComponents}
						value={selected_graph}
						// isMulti
						options={[{value:'line', label:'Line'}, {value:'bar', label:'Bar'}]}
						// styles={colourStyles}
						onChange={(selectedOptions) => set_selected_graph(selectedOptions)}
					/>
				</div>
			</div>
			{
				selected_graph.value === 'line'?<Line options={options} data={data} />:<Bar options={options} data={data} />
			}
		</div>
	) 
}

export default UsageGraph