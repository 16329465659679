import React, { useCallback, useEffect, useMemo, useState } from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';

// reactstrap components
import { Container } from "reactstrap";

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import StoreDashboard from "../views/StoreDashboard";
import Sidebar from "../components/Sidebar";
import routes from "../routes.js";
import {get_user_details, get_investor_details, get_all_license_plans, get_all_stores, get_usage} from '../actions';
import general_fetch from "../utils/fetch.js";
import * as Sentry from "@sentry/react";
import OutofOffice from "../views/OutofOffice/index.jsx";
import PaidOrgs from "../views/PaidOrgs/index.jsx";
import UnpaidOrgs from "../views/UnpaidOrgs/index.jsx";
import ChurnedOrgs from "../views/ChurnedOrgs/index.jsx";
import Payments from "../views/Payments/index.jsx";
import Coupons from "../views/Coupons/index.js";
import Languages from "../views/Languages/index.jsx";
import Geography from "../views/Geography/index.jsx";
import CSExecutives from "../views/CsExecutives/index.jsx";
import CsAssignments from "../views/CsAssignments/index.jsx";
import Bdreps from "../views/BdReps/index.jsx";
import BdGroups from "../views/BdGroups/index.js";
import Activities from "../views/Activities/index.jsx";
import Teams from "../views/Teams/index.jsx";
import ActivityReports from "../views/ActivityReports/index.jsx";
import CustomerTickets from "../views/CustomerTickets/index.jsx";
import BackOfficeTickets from "../views/BackOfficeTickets/index.jsx";
import generic_sort from "../utils/generic_sort.js";
import CohortAnalysis from "../views/CohortAnalysis/index.jsx";
import ClientsAtRisk from "../views/ClientsAtRisk/index.js";
import GChatPersonalSpaceModal from "../components/GChatPersonalSpaceModal/index.js";
import ReleaseNotes from "../views/ReleaseNotes/index.js";
import FeatureGuides from "../views/FeatureGuides/index.js";

const csp_allowed_origins = ["https://stagecsp.infurnia.com", "https://preprodcsp.infurnia.com", "https://csp.infurnia.com", "https://sales_local_dev.infurnia.com", "https://localsales.infurnia.com", "https://devdesign2.infurnia.com", "https://devadmin2.infurnia.com", "https://localcsp.infurnia.com"]
const bdp_allowed_origins = ["https://stagebdp.infurnia.com", "https://preprodbdp.infurnia.com", "https://bdp.infurnia.com", "https://sales_local_dev.infurnia.com", "https://localsales.infurnia.com", "https://devdesign2.infurnia.com", "https://devadmin2.infurnia.com", "https://localcsp.infurnia.com"]

const ToastError = ({}) => {
	const [toast_error, set_toast_error] = useState('');
	global.set_toast_error = set_toast_error

	useEffect(() => {
		if(toast_error){
			toastr.error(toast_error)
			set_toast_error('')
		}
	}, [toast_error]);

	return(<></>)
}

let today = new Date(new Date().toDateString());
let last_month = new Date()
last_month.setDate(today.getDate()-31)

const AdminLayout = ({}) => {
	const [is_authenticating, set_is_authenticating] = useState(true);
	const [page_loader, set_page_loader] = useState({  
														show: false,
														text: "Please wait ..."
													});
	const [cs_org_users, set_cs_org_users] = useState([]);
	const [all_stores, set_all_stores] = useState([]);
	const [user_details, set_user_details] = useState();
	const filtered_routes = useMemo(() => {
											let temp_routes = routes
											if(window.location.origin.includes('csp')){
												temp_routes = temp_routes.filter(x => !x.bd_only)
											}else if(window.location.origin.includes('bdp')){
												temp_routes = temp_routes.filter(x => !x.cs_only)
											}
											return temp_routes
											},[routes])
	const [infurnia_cs_reps, set_infurnia_cs_reps] = useState([]);
	const [infurnia_bd_reps, set_infurnia_bd_reps] = useState([]);
	const [is_cs_admin, set_is_cs_admin] = useState(false);
	const [is_team_lead, set_is_team_lead] = useState(false);
	const [infurnia_teams, set_infurnia_teams] = useState([]);
	const [activity_metadata, set_activity_metadata] = useState();
	const [show_gchat_personal_space_ui, set_show_gchat_personal_space_ui] = useState(false);
	const [ fetched_cohort_analysis_data, set_fetched_cohort_analysis_data ] = useState();
	const [ query_param_id, set_query_param_id ] = useState('');

	const fetch_cs_org_users = async () => {
		try {
			let account_details = await general_fetch({ url: 'user/get_all' });
			set_cs_org_users(account_details)
		} catch (err) {
			console.error(err)
		}
	}

	const fetch_infurnia_cs_reps = async() => {
		try {
			let resp = await general_fetch({url:'customer_success_executive/get'})
			if(resp && resp.length){
				for(let i=0;i<resp.length;i++){
					let user = cs_org_users.find(o => o.id === resp[i].user_id)
					resp[i].first_name = user ? user.first_name : ''
					resp[i].email = user? user.email : ''
				}
				set_infurnia_cs_reps(resp)
			}
		}catch (err) {
			console.error(err)
		}
	}

	const fetch_infurnia_bd_reps = async() => {
		try {
			let resp = await general_fetch({url:'bd_representative/get'})
			resp = resp.bd_representatives
			if(resp && resp.length){
				for(let i=0;i<resp.length;i++){
					let user = cs_org_users.find(o => o.id === resp[i].user_id)
					resp[i].first_name = user ? user.first_name : ''
					resp[i].email = user? user.email : ''
				}
				set_infurnia_bd_reps(resp)
			}
		}catch (err) {
			console.error(err)
		}
	}

	const get_user_details = async () => {
		try {
			let resp = await general_fetch({url:'user/get_details', data_field:'user'});
			if(resp && resp.user_details){
				set_user_details(resp.user_details)
				set_is_cs_admin(resp.user_details && resp.user_details.role && resp.user_details.role.store.includes('customer_success_admin'))
				Sentry.configureScope(function(scope) {
					scope.setUser({
						id: resp.user_details.id,
						email: resp.user_details.email
					});
				});
			}
            set_is_authenticating(false)
		}catch(err) {
			set_is_authenticating(false)
			console.error(err)
		}
	}

	const fetch_all_stores = async() => {
		try {
			let resp = await general_fetch({url:'csp/get_all_of_business_type'})
			resp.sort((a, b) => generic_sort(a, b, 'license_start_date', 1, 'date'))
			set_all_stores(resp)
		}catch(err) {
			console.error(err)
		}
	}

	const fetch_teams = async() => {
		try{
            var resp = await general_fetch({url: 'team/get'});
			set_infurnia_teams(resp)
			if(resp && resp.length && resp.find(o => o.team_lead_id === user_details.id)) set_is_team_lead(true)
		}catch(err){
			console.error(err)
		}
	}

	const check_gchat_webhook_url = async() => {
		try{
            let resp = await general_fetch({url: 'csp/check_gchat_webhook_url'});
			if(resp){
				if(!resp.url_stored){
					set_show_gchat_personal_space_ui(true)
				}else{
					set_show_gchat_personal_space_ui(false)
				}
			}
		}catch(err){
			console.error(err)
		}
	}
	
	const fetch_activity_metadata = async() => {
		try{
			let resp = await general_fetch({url:'cs_backend/activity/get_activity_metadata', body:{org_ids: all_stores.map(x => x.id)}})
			// let resp = await general_fetch({full_url:'https://stagebackend5.infurnia.com/activity/get_activity_metadata', body:{org_ids: all_stores.map(x => x.id)}, credentials:'omit'})
			set_activity_metadata(resp)
			// console.log('')
		}catch(err){
			console.error(err)
		}
	}

	const check_cs = () => {
		var check = false;
		check = (user_details.role.store && user_details.role.store.includes('customer_success_admin')) || (user_details.role.store && user_details.role.store.includes('customer_success_executive'))
		return check;
	}

	const logout_and_redirect = async() => {
		if(user_details){
            try {
                let resp = await general_fetch({url:'user/logout'})
            } catch(err){
                console.log(err)
            }
		}
		window.location.href = global.config.auth_redirect_path
	}

	const getRoutes  = useCallback(() => {
		return routes.map((prop, key) => {
			console.log('pathname', window.location.pathname)
			if (prop.layout === "/home") {
			  return (
				  <Route
					  path={prop.layout + prop.path}
					  component={(routeProps) => (
					  <prop.component
						  set_page_loader={set_page_loader}
						  all_stores={all_stores}
						  user_details={user_details}
						  cs_org_users = {cs_org_users}
						  {...routeProps}
					  />
					  )}
					  // key={window.location.pathname ? window.location.pathname : key}
				  />
			  );
			} else {
			  return null;
			}
		  });
	}, [routes])

	

	useEffect(() => {
		get_user_details()
	}, []);

	useEffect(() => {
		let currentURL = new URL(document.URL)
		set_query_param_id(currentURL.searchParams.get('id'))
	}, [window.location]);

	useEffect(() => {
		if(user_details && user_details.id) {
			fetch_all_stores()
			fetch_cs_org_users()
			fetch_teams()
			if(user_details.role && user_details.role.store && user_details.role.store.length && (user_details.role.store.includes('cs_rep') || user_details.role.store.includes('bd_rep') || user_details.role.store.includes('infurnia_catalog_admin') || user_details.role.store.includes('infurnia_product_manager'))) check_gchat_webhook_url()
		}
	}, [user_details]);

	useEffect(() => {
		if(cs_org_users && cs_org_users.length > 0) {
			fetch_infurnia_cs_reps()
			fetch_infurnia_bd_reps()
		}
	}, [cs_org_users]);

	useEffect(() => {
		if(all_stores && all_stores.length){
			fetch_activity_metadata()
		}
	}, [all_stores]);

	useEffect(() => {
		if(window.location.origin.includes('bdp')) document.title = 'Infurnia | Business Development Portal'
	}, []);

	return (
		is_authenticating ? '' : (
			!user_details || !check_cs() || !(user_details.store_id == '1522492495145') ?
				<div className="flex_property" style={{ alignItems: 'center', justifyContent: 'center', height: '100vh', fontSize: '20px', flexDirection: 'column' }}>
					{console.log('rendereddddddddddddddddddddddddddddddddd')}
					<div style={{ color: 'red' }}>You don't have access to {window.location.origin.includes('bdp') ? 'Business Development' : 'Customer Success'} Portal !</div>
					<div style={{ color: '#007EF4', textDecoration: 'underline', fontSize: '19px', cursor: 'pointer', marginTop: '12px' }} onClick={() => logout_and_redirect()}>Go to Login Portal</div>
				</div> :
				<div className="">
					<ToastError />
					<GChatPersonalSpaceModal open={show_gchat_personal_space_ui} toggle={() => set_show_gchat_personal_space_ui(false)} user_details={user_details}/>
					{page_loader && page_loader.show ? (
						<div style={{ position: 'fixed', backgroundColor: 'rgba(20,20,20,0.7', left: 0, top: 0, width: '100%', height: '100%', zIndex: 10000 }}>
							<div style={{ position: 'absolute', width: '350px', height: '40px', left: 'calc(50% - 175px)', top: 'calc(50% - 20px)', backgroundColor: '#314456', color: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px' }}>
								<i className="fa fa-spinner fa-spin"></i> &nbsp; &nbsp; <span>{page_loader.text}</span>
							</div>
						</div>
					) : ''}
					<Sidebar
						user_details={user_details}
						routes={is_cs_admin ? filtered_routes : (is_team_lead ? filtered_routes.filter(x => !x.admin_only) : filtered_routes.filter(x => !x.admin_only && !x.team_lead_only))}
						logo={{
							innerLink: "/",
							imgSrc: require("../assets/img/brand/blackLogo.png"),
							imgAlt: "..."
						}}
					/>
					<div className="main-content" style={{minWidth:'768px', overflow:'auto'}} >
						<Switch>
							<Route 
								path='/home/paid_orgs'
								render={({match}) => csp_allowed_origins.includes(window.location.origin) ? <PaidOrgs all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} activity_metadata={activity_metadata} fetch_activity_metadata={fetch_activity_metadata} /> : ''}
							/>
							<Route 
								path='/home/churned_orgs'
								render={({match}) => csp_allowed_origins.includes(window.location.origin) ? <ChurnedOrgs all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} activity_metadata={activity_metadata} fetch_activity_metadata={fetch_activity_metadata} fetch_all_stores={fetch_all_stores} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} /> : ''}
							/>
							<Route 
								path='/home/unpaid_orgs'
								render={({match}) => csp_allowed_origins.includes(window.location.origin) ? <UnpaidOrgs all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} activity_metadata={activity_metadata} fetch_activity_metadata={fetch_activity_metadata} /> : ''}
							/>
							<Route 
								path='/home/onboarding'
								render={({match}) => bdp_allowed_origins.includes(window.location.origin) ? <Payments all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} /> : ''}
							/>
							<Route 
								path='/home/coupons'
								render={({match}) => bdp_allowed_origins.includes(window.location.origin) ? <Coupons all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} /> : ''}
							/>
							<Route 
								path='/home/languages'
								render={({match}) => <Languages all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} />}
							/>
							<Route 
								path='/home/geography'
								render={({match}) => <Geography all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} />}
							/>
							<Route 
								path='/home/cs_executives'
								render={({match}) => csp_allowed_origins.includes(window.location.origin) ? <CSExecutives all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} fetch_infurnia_cs_reps={fetch_infurnia_cs_reps} is_cs_admin={is_cs_admin} is_team_lead={is_team_lead} infurnia_teams={infurnia_teams}/> : ''}
							/>
							<Route 
								path='/home/cs_assignments'
								render={({match}) => csp_allowed_origins.includes(window.location.origin) ? <CsAssignments all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} /> : ''}
							/>
							<Route 
								path='/home/out_of_office'
								render={({match}) => <OutofOffice user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} infurnia_cs_reps={infurnia_cs_reps} infurnia_bd_reps={infurnia_bd_reps} />}
							/>
							<Route 
								path='/home/bd_reps'
								render={({match}) => bdp_allowed_origins.includes(window.location.origin) ? <Bdreps all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} fetch_infurnia_bd_reps={fetch_infurnia_bd_reps} is_team_lead={is_team_lead} is_cs_admin={is_cs_admin} infurnia_teams={infurnia_teams} /> : ''}
							/>
							<Route 
								path='/home/bd_groups'
								render={({match}) => bdp_allowed_origins.includes(window.location.origin) ? <BdGroups all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} /> : ''}
							/>
							<Route 
								path='/home/activities'
								render={({match}) => csp_allowed_origins.includes(window.location.origin) ? <Activities all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} /> : ''}
							/>
							<Route 
								path='/home/teams'
								render={({match}) => <Teams all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} set_infurnia_teams={set_infurnia_teams} />}
							/>
							<Route
								path='/home/activity_reports'
								render={({match}) => csp_allowed_origins.includes(window.location.origin) ? <ActivityReports user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} is_cs_admin={is_cs_admin} is_team_lead={is_team_lead} infurnia_teams={infurnia_teams} /> : ''}
							/>
							<Route 
								path='/home/customer_tickets'
								render={({match}) => <CustomerTickets key={query_param_id} all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} />}
							/>
							<Route 
								path='/home/back_office_tickets'
								render={({match}) => <BackOfficeTickets all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} />}
							/>
							<Route 
								path='/home/cohort_analysis'
								render={({match}) => <CohortAnalysis all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} fetched_cohort_analysis_data={fetched_cohort_analysis_data} set_fetched_cohort_analysis_data={set_fetched_cohort_analysis_data} />}
							/>
							<Route 
								path='/home/orgs_at_risk'
								render={({match}) => <ClientsAtRisk all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} is_team_lead={is_team_lead} is_cs_admin={is_cs_admin} infurnia_teams={infurnia_teams}/>}
							/>
							<Route 
								path='/home/release'
								render={({match}) => csp_allowed_origins.includes(window.location.origin) && user_details && user_details.role && user_details.role.store.includes('content_manager') ? <ReleaseNotes all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} is_team_lead={is_team_lead} is_cs_admin={is_cs_admin} infurnia_teams={infurnia_teams}/> : ''}
							/>
							<Route 
								path='/home/feature_guide'
								render={({match}) => csp_allowed_origins.includes(window.location.origin) && user_details && user_details.role && user_details.role.store.includes('content_manager') ? <FeatureGuides all_stores={all_stores} user_details={user_details} cs_org_users={cs_org_users} set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} is_team_lead={is_team_lead} is_cs_admin={is_cs_admin} infurnia_teams={infurnia_teams}/> : ''}
							/>
							<Route
								path='/home/store_dashboard/:store_id/:tab_id?' 
								render={({ match }) => 
									<StoreDashboard match={match} 
										all_stores={all_stores}
										fetch_all_stores={fetch_all_stores} 
										user_details={user_details} 
										set_page_loader={(a, b) => set_page_loader({ show: a, text: b })} 
										cs_org_users={cs_org_users} 
									/>
								} 
								key={routes.length} 
								user_details={user_details} 
							/>
						</Switch>
					</div>
				</div>
		)
	)
}

export default AdminLayout