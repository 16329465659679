import React, { useEffect, useMemo, useState } from "react";
import general_fetch from "../../utils/fetch";
import './styles.css'
import { Tooltip } from "antd";
import { toastr } from "react-redux-toastr";
import { date_string } from "../../utils/date_string";
import generic_sort from "../../utils/generic_sort";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Button} from 'reactstrap'
import AddEditActivity from "../AddEditActivity";
import DeleteNotification from "../DeleteNotification";
import { confirmAlert } from "react-confirm-alert";
import timeSince from "../../utils/timeSince";
import { CspModal, CspModalBody, CspModalFooter, CspModalHeader } from "../CspModal";
import { time_string } from "../../utils/time_string";
import { convert_to_readable } from "../../utils/convert_to_readable";
const activity_types = ['call', 'meeting', 'task', 'deadline', 'email', 'onboarding', 'note']
const activity_type_icons = {'call':'phone', 'meeting':'users', 'task':'clock', 'deadline':'flag', 'email':'envelope', 'onboarding':'handshake', 'note':'sticky-note'}

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
		title: 'Confirm to submit',
		message: message,
		customUI: ({ onClose }) => {
		return (
			Custom_modal ?
			<div>
				<Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
			:
			<div className='custom-ui'>
				<DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
		);
		}
	});
}

const timeline = (given_date) => {

	if(new Date() > new Date(given_date) && new Date().toDateString() == new Date(given_date).toDateString()){
		return 'Overdue Today'
	}else if(new Date() > new Date(given_date)){
		return 'Overdue'
	}else if(new Date().toDateString() == new Date(given_date).toDateString()){
		return 'Today'	
	} else{
		return date_string(given_date)
	}
}

const NoteEditModal = ({open, toggle, active_note, submit_fn}) => {

	const [edited_content, set_edited_content] = useState();

	const onclick_handleclose = () => {
		set_edited_content('')
		toggle()
	}

	useEffect(() => {
		if(active_note && active_note.content) set_edited_content(active_note.content)
	}, [active_note]);

	return(
		<CspModal open={open} toggle={onclick_handleclose}>
			<CspModalHeader title='Update Note' toggle={onclick_handleclose}/>
			<CspModalBody>
				<textarea className="org_notes_textarea mt-0" value={edited_content} onChange={(e) => set_edited_content(e.target.value)}/>
				<div className="flex_property mt-2" style={{justifyContent:'flex-end', }}>
					<Button color='primary' onClick={() => {submit_fn(active_note.note_id, edited_content); onclick_handleclose()}}>Update</Button>
				</div>
			</CspModalBody>
		</CspModal>
	)
}

const OrgActivity = ({store_info, user_details, set_page_loader, cs_org_users, all_stores}) => {

	const [activities, set_activities] = useState([]);
	const [show_focused_activities, set_show_focused_activities] = useState(true);
	const [show_history_activities, set_show_history_activities] = useState(true);
	const [active_activity, set_active_activity] = useState();
	const [show_add_edit_activity, set_show_add_edit_activity] = useState(false);
	const [mode, set_mode] = useState();
	const [new_note_text, set_new_note_text] = useState();
	const [notes, set_notes] = useState();
	const [active_note, set_active_note] = useState();
	const [show_note_edit_modal, set_show_note_edit_modal] = useState();
	const [note_expand_json, set_note_expand_json] = useState({});
	const [selected_types, set_selected_types] = useState({'call':1, 'meeting':1, 'task':1, 'deadline':1, 'email':1, 'onboarding':1, 'note':1});

	const get_focused_of_org = () => {
		let temp_activities = activities && activities.length ? JSON.parse(JSON.stringify(activities.filter(x => x.status !== 'done' && selected_types[x.type]))) : []
		temp_activities = temp_activities.map(x => {return {...x, sort_date: x.due_date}})
		temp_activities.sort((a,b) => generic_sort(a,b,'sort_date', -1, 'date'))
		let temp_focused = [...temp_activities]
		return temp_focused
	}

	const get_history_of_org = () => {
		let temp_activities = activities && activities.length ? JSON.parse(JSON.stringify(activities.filter(x => x.status === 'done' && selected_types[x.type]))) : []
		temp_activities = temp_activities.map(x => {return {...x, sort_date: x.due_date}})
		let temp_notes = notes && notes.length && selected_types['note'] ? JSON.parse(JSON.stringify(notes)) : []
		temp_notes = temp_notes.map(x => {return {...x, sort_date: x.created_at}})
		let temp_focused = [...temp_activities, ...temp_notes]
		temp_focused.sort((a,b) => generic_sort(a,b,'sort_date', 1, 'date'))
		return temp_focused
	}

	const get_pinned_notes = () => {
		let temp_notes = notes && notes.length ? JSON.parse(JSON.stringify(notes.filter(x => x.pinned))) : []
		if(temp_notes && temp_notes.length){
			note_expand_json[temp_notes[0].note_id] = true
		}
		return temp_notes
	}
	
	const focused_activities = useMemo(get_focused_of_org, [activities, notes, selected_types])
	const history_activities = useMemo(get_history_of_org, [activities, notes, selected_types])
	const pinned_notes = useMemo(get_pinned_notes, [notes, selected_types])

	const get_org_activity = async() => {
		try {
			let payload = {
				org_id: store_info.id,
			}
		// 	const full_url = 'https://stagebackend5.infurnia.com/' + 'activity/get_by_org'

		// 	fetch(full_url, {
		// 	method: 'POST', 
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// 	body: JSON.stringify(payload),
		// 	})
		// 	.then(response => {
		// 		if (!response.ok) {
		// 		throw new Error(`HTTP error! Status: ${response.status}`);
		// 		}
		// 		return response.json();
		// 	})
		// 	.then(data => {
		// 		console.log('activities', data);
		// 		set_activities(data.data);
		// })
		// 	.catch(error => {
		// 		console.error('Error:', error);
		// 	});
			let resp = await general_fetch({url:'cs_backend/activity/get_by_org', body:payload})
			if(resp && resp.length){
				resp = resp.map((x) => {return {...x, sort_date:x.due_date}})
			}
			set_activities(resp)
		}catch (err) {
			console.error(err);
		}
	}

	const update_status = async(activity_id, status) => {
		try{
			// const full_url = 'https://stagebackend5.infurnia.com/' + 'activity/update'; 
			let body = {activity_id, status, cs_executive_id_of_org : store_info.customer_success_executive_id}
			let resp = await general_fetch({url:'cs_backend/activity/update', body,});
			// let resp = await fetch(full_url, {
			// 						method: 'POST', 
			// 						headers: {
			// 							'Content-Type': 'application/json',
			// 						},
			// 						body: JSON.stringify(body),
			// 					})
			if(status === 'done'){
				set_mode('add')
				set_show_add_edit_activity(true)
			}
			if(resp.error){
				toastr.error(resp.error.message)
				return
			}
			get_org_activity()
		}catch(err){
			console.error('Error:', err);
			toastr.error(`Couldn't update status, please try again`)
		}
	}

	const delete_fn = async(id) => {
		let payload = {
			activity_id: id,
		}
	// 	const full_url = 'https://stagebackend5.infurnia.com/activity/delete'; 

	// 	fetch(full_url, {
	// 	method: 'POST', 
	// 	headers: {
	// 		'Content-Type': 'application/json',
	// 	},
	// 	body: JSON.stringify(payload),
	// 	})
	// 	.then(response => {
	// 		if (!response.ok) {
	// 		throw new Error(`HTTP error! Status: ${response.status}`);
	// 		}
	// 		return response.json();
	// 	})
	// 	.then(data => {
	// 		console.log('activities', data);
	// 		get_org_activity()
	// })
	// 	.catch(error => {
	// 		console.error('Error:', error);
	// 	});

		try{
			let resp = await general_fetch({url:'cs_backend/activity/delete', body:payload})
			get_org_activity()
		}catch(error){
			console.error('Error:', error);
		}
	}

	const get_org_notes = async() => {
		try {
			let resp = await general_fetch({url:'cs_backend/note/get_by_org', body:{org_id:store_info.id}})
			if(resp && resp.length){
				resp = resp.map((x) => {return {...x, sort_date:x.created_at}})
			}
			set_notes(resp)
		}catch (err){
			console.error('Error:', err);
		}
	}

	const create_note = async(content) => {
		try {
			let resp = await general_fetch({url:'cs_backend/note/create', body:{content, org_id:store_info.id}})
			set_new_note_text('')
			get_org_notes()
		}catch (err) {
			console.error(err)
		}
	}

	const update_note = async(note_id, content, pinned) => {
		try {
			let body = {note_id}
			if(pinned != null) body.pinned = pinned
			if(content != null) body.content = content
			let resp = await general_fetch({url:'cs_backend/note/update' , body})
			get_org_notes()
		}catch (err) {
			console.error(err)
		}
	}

	const delete_note = async(note_id) => {
		try {
			let resp = await general_fetch({url:'cs_backend/note/delete' , body:{note_id}})
			get_org_notes()
		}catch(err) {
			console.error(err)
		}
	}

	const onclick_all_types = () => {
		let all_selected = 1
		activity_types.map((single_type, index) => {
			if(!selected_types[single_type]) all_selected = 0
		})
		activity_types.map((single_type, index) => {
			all_selected ? selected_types[single_type] = 0 : selected_types[single_type] = 1 	
		})
		set_selected_types(JSON.parse(JSON.stringify(selected_types)))
	}

	useEffect(() => {
		if(store_info && store_info.id){
			get_org_activity()
			get_org_notes()
		}
	}, [store_info]);

	useEffect(() => {
		console.log('cs_org_userscs_org_users', cs_org_users)
	}, [cs_org_users]);

	const list_activities = (activities) => (
		activities && activities.length ? activities.map((activity, idx) => (
			<div style={{display:'flex', position:'relative'}} className="mb-4">
				<div className="activity_type_div mr-4">
				{activity.activity_id ? <i className={`fa fa-${activity_type_icons[activity.type]}`} /> : <i className={`far fa-sticky-note`} />}
				</div>
				{(idx != activities.length-1) ? <div className="vertical_line"></div>  : ''}
				<div className="activity_box" style={{backgroundColor:activity.activity_id ? '' : '#faf1e4', }}>
					<div className="flex_center" style={{justifyContent:'space-between'}}>
					{
						activity.activity_id?
						<>
							<div className="flex_center">
								{
									activity.status != 'done' ? 
									<Tooltip title="Mark as done" placement="top">
										<i className="far fa-circle cp" onClick={() => update_status(activity.activity_id, 'done')} style={{fontSize:'14px', color:'#525f7f'}}/>
									</Tooltip>:
									<Tooltip title="Mark as not done" placement="top">
										<i className="fa fa-check-circle cp" onClick={() => update_status(activity.activity_id, 'not_done')} style={{fontSize:'16px', color:'#1aae1a'}}/>
									</Tooltip>
								}
								<b className="ml-1" style={{fontSize:'15px'}}>{activity.subject}</b>
							</div>
							<div className="flex_property">
								<i className="fa fa-edit cp mr-1" onClick={() => {set_active_activity(activity); set_show_add_edit_activity(true); set_mode('edit')}} style={{fontSize:'14px'}} />
								<UncontrolledDropdown>
									<DropdownToggle style={{padding:'4px 0px 4px 4px', background:'unset', boxShadow:'unset', border:'unset'}}>
										<i className="fas fa-ellipsis-h cp"></i>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem onClick={() => {set_active_activity(activity); set_show_add_edit_activity(true); set_mode('edit')}}>Edit</DropdownItem>
										<DropdownItem onClick={() => update_status(activity.activity_id, activity.status==='done'?'not_done':'done')}>{activity.status==='done' ? 'Mark as not done' : 'Mark as done'}</DropdownItem>
										<DropdownItem onClick={() => submit(() => delete_fn(activity.activity_id), ()=>{}, 'Are you sure you want to delete this Activity?', '','')}>Delete</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						</>:
						<>
							<div className="flex_center">
								{timeSince(activity.updated_at)}
								<i className="fas fa-circle mx-1" style={{fontSize:'2px'}}/>
								<div>{cs_org_users && cs_org_users.length && cs_org_users.find(o => o.id === activity.updated_by) ? cs_org_users.find(o => o.id === activity.updated_by).first_name : 'Unknown user'}</div>	
							</div>
							<div className="flex_center">
								<img src={`/svgs/${note_expand_json[activity.note_id] ? 'arrows_converging.svg' : 'arrows_up_down.svg'}`} onClick={() => {note_expand_json[activity.note_id] = !note_expand_json[activity.note_id]; set_note_expand_json(JSON.parse(JSON.stringify(note_expand_json)))}} style={{height:'18px', width:'18px'}} className="cp mr-1"/>
								<Tooltip title={activity.pinned ? 'Unpin this note' : 'Pin this note'} placement="top">
									<img src={`/svgs/${activity.pinned ? 'unpin.svg' : 'pin.svg'}`} onClick={() => update_note(activity.note_id, null, !activity.pinned)} style={{height:'16px', width:'16px'}} className="cp mr-1"/>
								</Tooltip>
								<UncontrolledDropdown>
									<DropdownToggle style={{padding:'4px 0px 4px 4px', background:'unset', boxShadow:'unset', border:'unset'}}>
										<i className="fas fa-ellipsis-h cp"></i>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem onClick={() => {set_active_note(activity); set_show_note_edit_modal(true)}}>Edit</DropdownItem>
										<DropdownItem onClick={() => update_note(activity.note_id, null, !activity.pinned)}>{activity.pinned ? 'Unpin this note' : 'Pin this note'}</DropdownItem>
										<DropdownItem onClick={() => submit(() => delete_note(activity.note_id), ()=>{}, 'Are you sure you want to delete this Note?', '','')}>Delete</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						</>
					}
					</div>
					<div className="flex_property mt-1" style={{}}>
					{
						activity.activity_id?
						<>
						{
							(timeline(activity.due_date)==='Overdue' || timeline(activity.due_date) === 'Overdue Today') && activity.status != 'done'?
							<>
								<div className="px-1" style={{color:'white', backgroundColor:'#d83c38', borderRadius:'8px'}}>
									Overdue
								</div>
								<i className="fas fa-circle ml-1" style={{fontSize:'2px'}}/>
								<div className="ml-1" style={{color:'#d83c38'}}>{timeline(activity.due_date) === 'Overdue Today' ? `Today at ${time_string(activity.due_date)}` : (date_string(activity.due_date) + ' at ' + time_string(activity.due_date))}</div>
							</>:(
								timeline(activity.due_date)==='Today'?
								<>
									<div style={{color:'#1aae1a'}}>
										Today at {time_string(activity.due_date)}
									</div>
									{/* <i className="fas fa-circle ml-1" style={{fontSize:'2px'}}/>
									<div className="ml-1">{date_string(activity.due_date) + ' at ' + time_string(activity.due_date)}</div> */}
								</>
								:
								<div>
									{date_string(activity.due_date) + ' at ' + time_string(activity.due_date)}
								</div>
							)
						}
						<i className="fas fa-circle mx-1" style={{fontSize:'2px'}}/>
						<div>{cs_org_users && cs_org_users.length && cs_org_users.find(o => o.id === activity.assignee_id) ? cs_org_users.find(o => o.id === activity.assignee_id).first_name : ''}</div>	
						</>:
							<div style={{overflow:note_expand_json[activity.note_id] ? '' : 'hidden', whiteSpace:note_expand_json[activity.note_id] ? '' : 'nowrap', textOverflow:'ellipsis',}}>
							{activity.content}
							</div>
					}
					</div>
					{
						activity.activity_id && activity.note ?
						<div style={{backgroundColor:'#faf1e4', margin:'0px -16px -6px -16px', padding:'8px 16px', display:'flex', justifyContent:'space-between'}} className="mt-2">
							<div style={{overflow:note_expand_json[activity.activity_id] ? '' : 'hidden', whiteSpace:note_expand_json[activity.activity_id] ? '' : 'nowrap', textOverflow:'ellipsis'}}>
								{activity.note}
							</div>
							<img src={`/svgs/${note_expand_json[activity.activity_id] ? 'arrows_converging.svg' : 'arrows_up_down.svg'}`} onClick={() => {note_expand_json[activity.activity_id] = !note_expand_json[activity.activity_id]; set_note_expand_json(JSON.parse(JSON.stringify(note_expand_json)))}} style={{height:'18px', width:'18px'}} className="cp mr-1"/>
						</div>:''
					}
				</div>
			</div>
		)) :'No activities'
	)

	return (
		<>
			<div className="flex_center mb-3" style={{justifyContent:'space-between', padding:'0px 15px'}}>
				<div className="mb-3">
					<div className="custom_time_filter">
						<div
							className={`nav-item`}
							onClick={onclick_all_types}>
							All
						</div>
						{
							activity_types.map((single_type, idx) => (
								<div
									className={`nav-item ${selected_types[single_type] && 'selected'} flex_center`}
									onClick={() => {selected_types[single_type] = !selected_types[single_type]; set_selected_types(JSON.parse(JSON.stringify(selected_types)))}}>
									<i className={`fa fa-${activity_type_icons[single_type]} mr-1`} style={{fontSize:'14px'}}/>
									{convert_to_readable(single_type)}
								</div>
							))
						}
					</div>
				</div>
				{
					(store_info && store_info.id) ? 
					<div className="flex_center cp" style={{color:'#0078ff'}} onClick={() => {navigator.clipboard.writeText(`https://www.infurnia.com/book-onboarding-session?cs_id=${store_info.customer_success_executive_id}&org_id=${store_info.id}`); toastr.success('Copied to clipboard !')}}>
						<i className="fas fa-link mr-1" />
						<div style={{textDecoration:'underline'}}>Onboarding Session Booking Link</div>
					</div>
					:''
				}
			</div>
			<div style={{flexWrap:'wrap', display:'flex', position:'relative'}}>
				<div className="order-xl-2 col-xl-5" style={{position:'relative'}}>
					<div style={{position:'sticky', top:'10px'}}>
						<textarea className="org_notes_textarea" placeholder="Type..." value={new_note_text} oninput="autoResize(this)" onChange={(e) => set_new_note_text(e.target.value)}/>
						<div className="mb-4" style={{textAlign:'end'}}>
							{new_note_text ? <Button onClick={() => set_new_note_text('')}>Cancel</Button> : ""}
							<Button className="ml-2 mr-0" onClick={() => new_note_text ? create_note(new_note_text) : ''} color="primary" style={{/*color:'white', backgroundColor:'#1aae1a',*/ opacity:new_note_text?'1':'0.5'}}>Create Note</Button>
						</div>
						{pinned_notes && pinned_notes.length ? list_activities(pinned_notes) : <div style={{opacity:'0.4'}}></div>}
					</div>
				</div>
				<div className="order-xl-1 col-xl-7">
					<div className="flex_property cp" onClick={() => set_show_focused_activities(!show_focused_activities)} style={{justifyContent:'space-between'}}>
						<div>
							<b>Todo</b>
							{
								!show_focused_activities ? <i className="fa fa-chevron-right ml-2" style={{marginTop:'2px'}}/>  : <i className="fa fa-chevron-down ml-2" /> 
							}
						</div>
						<Button className="ml-2 mr-0" onClick={(e) => {set_show_add_edit_activity(true); set_mode('add');e.stopPropagation()}} color="primary" /*style={{color:'white', backgroundColor:'#1aae1a'}}*/>Create Activity</Button>
					</div>
					{
						show_focused_activities?
						<div className="mt-4">
						{list_activities(focused_activities)}
						</div>:''
					}

					<div className="flex_property cp mt-4" onClick={() => set_show_history_activities(!show_history_activities)}>
						<b>History</b>
						{
							!show_history_activities ? <i className="fa fa-chevron-right ml-2" style={{ marginTop: '2px' }} /> : <i className="fa fa-chevron-down ml-2" />
						}
					</div>
					{
						show_history_activities ?
						<div className="mt-4">
							{list_activities(history_activities)}
						</div> : ''
					}
				</div>
			</div>

			<AddEditActivity
				open={show_add_edit_activity}
				toggle={() => {set_show_add_edit_activity(false); set_mode(''); set_active_activity('')}}
				mode={mode}
				active_activity={active_activity}
				cs_org_users={cs_org_users}
				user_details={user_details}
				get_activities={get_org_activity}
				all_orgs = {all_stores}
				set_page_loader={set_page_loader}
				current_org_users = {store_info && store_info.all_users ? [...store_info.all_users] : []} 
				current_org={store_info ? store_info : ''}
				open_create_activity_modal={() => {set_mode('add');set_show_add_edit_activity(true)}}
				cs_executive_id_of_org = {store_info && store_info.customer_success_executive_id}
			/>
			<NoteEditModal 
				open={show_note_edit_modal}
				toggle={() => {set_show_note_edit_modal(false); set_active_note()}}
				active_note = {active_note}
				submit_fn = {(note_id, content) => {update_note(note_id, content, null); set_active_note()}}
			/>
		</>
	)
}

export default OrgActivity