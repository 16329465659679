const generic_sort = ( a,b,field,order=1, type ) => {
	if(a[field]==''|| a[field]===null||a[field]){
		// console.log([field]')
		if (!a[field]) {
			return -1*order;
		}
		if (!b[field]) {
			return 1*order;
		}
		if(type == 'number'){
			if(a[field] < b[field]) { 
				return -1*order;
			}
			if(a[field] > b[field]) { 
				return 1*order;
			}
		}else if(type == 'date'){
			return order*(new Date(b[field]) - new Date(a[field]));
		}else{
			if(String(a[field]).toLowerCase() < String(b[field]).toLowerCase()) { 
				return -1*order;
			}
			if(String(a[field]).toLowerCase() > String(b[field]).toLowerCase()) { 
				return 1*order;
			}
		}
		return 0;
	}
    
    return -1*order;
}

export default generic_sort;